$(function () {
	$('#datetimepicker-birth').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker1').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker2').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker3').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker4').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker5').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker6').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker7').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker8').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker9').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker10').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});
	$('#datetimepicker-card-exp').datetimepicker({
		ignoreReadonly: true,
		allowInputToggle: true,
		format: 'L',
		minDate: moment(),
		daysOfWeekDisabled: [1, 2, 3, 4, 5]
	});

});